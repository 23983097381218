<route lang="yaml">
  meta:
    navbar:
      enabled: true
    footer:
      enabled: true
    title: "8D-1 Assistants"
    type: "website"
    url: "https://8d-1.com"
    image: "https://8d-1.com/assets/illustrations/8dmain.png"
    description: "Make AI your initial point of contact. Instantly create and share personal AI agents for any case."
    og:title: "8D-1 Assistants"
    og:type: "website"
    og:url: "https://8d-1.com"
    og:image: "https://8d-1.com/assets/illustrations/8dmain.png"
    og:description: "Make AI your initial point of contact. Instantly create and share personal AI agents for any case."
  </route>
<script setup lang="ts">
import { type IconBoxColor } from '/@src/components/base/icon/IconBox.vue'
import { onMounted } from 'vue'
import { usePublicStats } from '/@src/composables/usePublicStats'

const { statistics, error, loading, fetchPublicStats } = usePublicStats()

onMounted(() => {
  fetchPublicStats()
})

const values = [
  {
    id: 0,
    image: '/assets/illustrations/product.png',
    darkImage: '/assets/illustrations/product.png',
    title: 'Product Management',
    content:
      'Take customer discovery on autopilot. Our AI chatbot can conduct interviews at scale, providing you with the data you need to make informed decisions.',
  },
  {
    id: 1,
    image: '/assets/illustrations/busdev.png',
    darkImage: '/assets/illustrations/busdev.png',
    title: 'Business Development',
    content:
      '8D-1 will not just chat with incoming leads; it will advocate for your brand, perform initial screenings, and provide you with comprehensive summaries.',
  },
  {
    id: 3,
    image: '/assets/illustrations/hr.png',
    darkImage: '/assets/illustrations/hr.png',
    title: 'Human Resources',
    content:
      'Never miss the next big talent. Let AI chat with potential candidates, screen them, and provide you with structured and professional reviews.',
  },
]

const featuresAll = [
  {
    title: 'Telegram Native',
    text: 'Just paste your Telegram bot API token to connect 8D-1 to Telegram.',
    color: 'info' as IconBoxColor,
    icon: 'feather:send',
  },
  {
    title: 'Unlimited Bots',
    text: 'Create as many bots as you need to cover all your business needs.',
    color: 'yellow' as IconBoxColor,
    icon: 'feather:grid',
  },
  {
    title: 'General Expertise',
    text: 'Powered by OpenAI, 8D-1 possesses general expertise in many fields.',
    color: 'red' as IconBoxColor,
    icon: 'feather:book-open',
  },
  {
    title: 'Chat Follow-ups',
    text: 'Join the chat when needed or reactivate it with a follow-up question.',
    color: 'primary' as IconBoxColor,
    icon: 'feather:activity',
  },
  {
    title: 'Customization',
    text: 'You can easily customize and fine-tune 8D-1 for your unique needs.',
    color: 'green' as IconBoxColor,
    icon: 'feather:settings',
  },
  {
    title: 'CSV Export',
    text: 'Export all data gathered by 8D-1 as a CSV to work with it further.',
    color: 'purple' as IconBoxColor,
    icon: 'feather:download',
  },
  {
    title: 'Email Notifications',
    text: 'Join the chat when needed or reactivate it with a follow-up question.',
    color: 'primary' as IconBoxColor,
    icon: 'feather:mail',
  },
  {
    title: 'File Search',
    text: '8D-1 accepts files during an interview and extracts required information.',
    color: 'green' as IconBoxColor,
    icon: 'feather:paperclip',
  },
]

const tasks = [
  {
    title: 'Handle LinkedIn inquiries',
    text: 'Never miss a potential partnership by ignoring LinkedIn messages. Let 8D-1 handle them for you.',
    color: 'green' as IconBoxColor,
    icon: 'feather:linkedin',
  },
  {
    title: 'Validate startup applications',
    text: 'Discover your next unicorn in the forest of pitch decks and application forms.',
    color: 'info' as IconBoxColor,
    icon: 'feather:mic',
  },
  {
    title: 'Pre-screen job candidates',
    text: 'Conduct initial screenings, ask relevant questions, and shortlist candidates for further interviews.',
    color: 'orange' as IconBoxColor,
    icon: 'feather:user-check',
  },
  {
    title: 'Do CustDev interviews',
    text: 'Understand your customers\' needs and preferences through in-depth, unscripted conversations.',
    color: 'red' as IconBoxColor,
    icon: 'feather:search',
  },
  {
    title: 'Collect feedback',
    text: 'Gather feedback from your clients to improve your products and services.',
    color: 'primary' as IconBoxColor,
    icon: 'feather:edit-3',
  },
  {
    title: 'And so much more...',
    text: 'You can fine-tune your assistant to fit your business needs perfectly.',
    color: 'default' as IconBoxColor,
    icon: 'feather:more-horizontal',
  }
];
</script>

<template>
  <div>
    <HeroSolutionsNoMessages
      powered-by-image-src="/assets/illustrations/gpt_logo.svg"
      powered-by-text="Powered by GPT-4o"
      title-text="You have <div><span class='text-success'>0</span></div> <div>new messages</div>"
      subtitle-text="Instantly create AI bots to ask and answer questions."
      primary-button-text="Start for free"
      primary-button-link="/app/create"
      secondary-button-text="Book demo"
      secondary-button-link="https://calendly.com/isokolov/30min"
    />

    <div class="is-flex is-justify-content-center mt-10 mb-10">
      <BasicQuote
        class="p-6"
        avatar-image="/assets/people/eduards.jpeg"
        name="Eduards Zolotuhins"
        title="Founder @ Innovators Club"
        :centered="true"
        quote="With AI interviews we keep a personal contact with our community members always knowing what they are looking for and what they can offer."
      />
    </div>
    



    <Section>
      <Container>
        <SideSection
          subtitle=""
          title="Automate your daily chat routines"
          content="Let AI handle your chats, so you can focus on bigger things and jump into the conversation when needed."
          image="/assets/illustrations/8d_ui.png"
          dark-image="/assets/illustrations/8d_ui_dark.png"
          image-width="800"
          image-height="625"
          to="app/create"
          cta="Skip hours of chatting"
        />
        <FeatureBlockH
          :features="tasks"
          :columns="4"
          :limit="8"
        />
      </Container>
    </Section>

    <Section>
      <Container>
        <div
          v-if="loading"
        />
        <div v-else-if="error" />
        <NumbersOnly
          v-else
          :numbers="statistics"
        />
      </Container>
    </Section>

    <Section>
      <Container>
        <SideSection
          reverse
          subtitle=""
          title="Get chat summaries"
          content="Get structured reports so you don't have to dive into every chat. Export reports to CSV or PDF"
          image="/assets/illustrations/report.png"
          dark-image="/assets/illustrations/report_dark.png"
          image-width="967"
          image-height="800"
          to="app/create"
          cta="Create your assistant"
        />
      </Container>
    </Section>
  

    <Section>
      <Container>
        <SectionTitle
          title="Time to let AI take over"
          subtitle=""
          class="mb-10 mt-20"
        />
        <ValuesSection :values="values" />
      </Container>
    </Section> 




    

    <Section>
      <Container>
        <SideSection
          subtitle="Case Study"
          title="Uncovering churn insights with Bitsgap"
          content="See why Bitsgap chose 8D-1's AI-powered interviews to gather comprehensive feedback"
          image="/assets/illustrations/bitsgap_case2.svg"
          dark-image="/assets/illustrations/bitsgap_case2_dark.svg"
          image-width="40"
          cta="Experience it yourself"
          to="/case-studies/bitsgap"
        />
      </Container>
    </Section> 

    <Section>
      <Container>
        <HowItWorks
          :columns="4"
          :limit="3"
          :links="false"
          :rounded="false"
          :inverted="false"
          title="Simple as a toaster"
          subtitle="Instantly create and share personalized AI agents for any task."
          :features="[
            {
              title: 'Create an assistant easily and share a link to your bot with your target audience.',
              image: '/assets/illustrations/task.svg',
              darkImage: '/assets/illustrations/task_dark.svg'
            },
            {
              title: 'Your assistant chats with your audience, gathering the information you need.',
              image: '/assets/illustrations/theychat.svg',
              darkImage: '/assets/illustrations/theychat_dark.svg'
            },
            {
              title: 'Check back to review chat transcripts and get insights into your audience.',
              image: '/assets/illustrations/result.svg',
              darkImage: '/assets/illustrations/result_dark.svg'
            }
          ]"
        />
      </Container>
    </Section> 

    <Section>
      <TestimonialBlockE
        image="/assets/illustrations/doktoroff.jpeg"
        name="Andrei Doktoroff"
        position="Serial Entrepreneur and VC"
        content="It's a fresh take on business communications."
        logo="/assets/illustrations/theintelligent.svg"
        curved
        reversed
      />
    </Section> 

    <Section>
      <Container>
        <SideSection
          subtitle="Case Study"
          title="Automating the VC application process"
          content="Check how theIntelligent VC streamline their application process with AI."
          image="/assets/illustrations/case-studies/landing/vc_case2.svg"
          dark-image="/assets/illustrations/case-studies/landing/vc_case2_dark.svg"
          image-width="40"
          cta="Try it yourself"
          to="/case-studies/theIntelligentVC"
          reverse
        />
      </Container>
    </Section> 

    <Section>
      <Container>
        <SectionTitle
          title="Ideal employee"
          subtitle=""
          class="mb-10"
        />
        <PricingSingle />
      </Container>
    </Section>

    <Section>
      <Container>
        <SectionTitle
          title="More than an average ChatGPT assistant"
          subtitle=""
        />
        <FeatureBlockC
          :features="featuresAll"
          :limit="9"
          size="medium"
          animated
          horizontal
        />
      </Container>
    </Section>

    <HeroM
      title="Delegate your formal communications to AI"
      mobile-title="Delegate your business chats to AI"
      subtitle="Get started in seconds – for free."
      create-button-text="Create Assistant"
      watch-demo-button-text="Watch Demo"
      quote-avatar="/assets/illustrations/AnnaShakola.gif"
      quote-name="Anna Shakola"
      quote-title="Head of Business Development"
      quote-text="8D-1 saves me from endless chats"
      hero-image="/assets/illustrations/ui_promo.png"
      hero-dark-image="/assets/illustrations/ui_promo_dark.png"
    />
  </div>
</template>