
// @ts-nocheck
import { type Plugin } from 'vue'
import { type Router } from 'vue-router'

// Extend the Window interface to include the trackdesk property
declare global {
  interface Window {
    trackdesk: (...args: any[]) => void;
  }
}

// Trackdesk click tracking
function initTrackdesk() {
  (function(t: Window, d: string, k: string){
    (t[k] = t[k] || []).push(d);
    t[d] = t[d] || (t[k] as any).f || function(...args: any[]){(t[d].q = t[d].q || []).push(args)}
  })(window, "trackdesk", "TrackdeskObject");

  window.trackdesk('8d1', 'click');
}

// Add CID to payment links
function addCidToPaymentLinks() {
  const cookie = document.cookie.match('(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)');
  if (Array.isArray(cookie)) {
    try {
      const trakdeskCid = JSON.parse(cookie.pop() || '{}');
      const cid = trakdeskCid['cid'];
      document.querySelectorAll('a[href^="https://buy.stripe.com/"], a[href^="https://billing.stripe.com/"]').forEach(function (a) {
        if (a instanceof HTMLAnchorElement) {
          const url = new URL(a.href);
          url.searchParams.set('client_reference_id', cid);
          a.href = url.href;
        }
      });
    } catch (e) {
      console.error('Error adding CID to payment links:', e);
    }
  }
}

// Track lead conversion
export function trackLeadConversion(amount?: number) {
  if (typeof window.trackdesk !== 'function') {
    console.error('Trackdesk not initialized');
    return;
  }

  if (amount !== undefined) {
    window.trackdesk('8d1', 'conversion', {
      conversionType: 'lead',
      amount: {
        value: amount.toString()
      }
    });
  } else {
    window.trackdesk('8d1', 'conversion', {
      conversionType: 'lead'
    });
  }
}

// Connect email to click
export function connectEmailToClick(email: string) {
  if (typeof window.trackdesk !== 'function') {
    console.error('Trackdesk not initialized');
    return;
  }

  window.trackdesk('8d1', 'externalCid', {
    externalCid: email,
    revenueOriginId: 'e608952f-9607-4bf2-aa0f-c602f9e75655'
  });
}

interface VulkAppContext {
  app: Plugin;
  router: Router;
}

export default function trackdeskPlugin({ app, router }: VulkAppContext) {
  if (typeof window === 'undefined') {
    return;
  }

  // Initialize Trackdesk
  initTrackdesk();

  // Add Trackdesk script to head
  const script = document.createElement('script');
  script.src = '//cdn.trackdesk.com/tracking.js';
  script.async = true;
  document.head.appendChild(script);

  // Add navigation guard to router for CID addition
  router.afterEach(() => {
    // Add a small delay to ensure the DOM has updated
    setTimeout(addCidToPaymentLinks, 100);
  });

  // Call addCidToPaymentLinks after app is mounted
  app.mixin({
    mounted() {
      addCidToPaymentLinks();
    }
  });

  // Make trackLeadConversion and connectEmailToClick available globally
  app.config.globalProperties.$trackLeadConversion = trackLeadConversion;
  app.config.globalProperties.$connectEmailToClick = connectEmailToClick;
}