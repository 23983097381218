<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useMediaQuery } from '@vueuse/core'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, EffectCards, Pagination } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-cards'
import 'swiper/css/pagination'


interface Solution {
  slug: string
  baseAssistantTask: string
  cover: string
  heroL: {
    tags: { text: string; color: string }[]
    title: string
    subtitle: string
  }
}

const solutions = ref<Solution[]>([])
const isLoading = ref(true)
const error = ref<string | null>(null)

const isDesktop = useMediaQuery('(min-width: 768px)')

const swiperModules = computed(() => {
  return isDesktop.value ? [Pagination] : [EffectCards, Autoplay]
})

const swiperEffect = computed(() => {
  return isDesktop.value ? 'slide' : 'cards'
})

const swiperOptions = computed(() => {
  return {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      clickable: true,
      el: '.swiper-pagination-container',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  }
})

onMounted(async () => {
  try {
    const context = import.meta.glob('/public/data/solutions/*.json', { eager: true })
    solutions.value = Object.values(context).map((module: any) => module.default)
    console.log('Loaded solutions:', solutions.value)
    isLoading.value = false
  } catch (err) {
    console.error('Error loading solution files:', err)
    error.value =
      err instanceof Error ? err.message : 'An unknown error occurred'
    isLoading.value = false
  }
})
</script>

<template>
  <div v-if="isLoading">
    Loading solutions...
  </div>
  <div v-else-if="error">
    Error: {{ error }}
  </div>
  <div
    v-else-if="solutions.length > 0"
    class="solutions-carousel"
  >
    <Swiper
      :key="swiperEffect"
      :modules="swiperModules"
      :effect="swiperEffect"
      :grab-cursor="true"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }"
      v-bind="swiperOptions"
      class="mySwiper"
    >
      <SwiperSlide
        v-for="solution in solutions"
        :key="solution.slug"
      >
        <div
          class="carousel__item"
          :style="{ backgroundImage: `url(${solution.cover})` }"
        >
          <RouterLink :to="{ path: `/solutions/${solution.slug}` }">
            <div class="card-content">
              <div class="tags">
                <Tag
                  v-for="tag in solution.heroL.tags"
                  :key="tag.text"
                  color="solid"
                  :label="tag.text"
                />
              </div>
              <Title
                :size="4"
                weight="bold"
                class="card-title"
              >
                {{ solution.baseAssistantTask }}
              </Title>
              <div class="title-container">
                <Subtitle
                  :size="6"
                  class="card-subtitle pb-4"
                  tag="h3"
                >
                  {{ solution.heroL.subtitle }}
                </Subtitle>
              </div>
            </div>
          </RouterLink>
        </div>
      </SwiperSlide>
    </Swiper>
    <div class="swiper-pagination-container"></div>
  </div>
  <div v-else>
    No solutions found.
  </div>
</template>

<style lang="scss" scoped>
.solutions-carousel {
  width: 300px;
  height: 400px;
  margin: 0 auto;
  min-width: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  overflow: hidden;
  border-radius: 18px;
  font-weight: bold;
}

.carousel__item {
  width: 100%;
  height: 100%;
  background: var(--card-border-color);
  background-size: cover;
  background-position: center;
}

.card-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0 !important;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.title-container {
  text-align: left;
  min-height: 3.5em;
}

.card-title,
.card-subtitle {
  color: var(--title-color);
  text-align: left;
}

.card-title {
  min-height: 2.5em;
}

@media (min-width: 768px) {
  .solutions-carousel {
    width: 100%;
    height: auto;
    //max-width: 1200px
  }

  .carousel__item {
    height: 400px;
  }

  .card-content {
    background: none;
    transition: background 0.5s ease;

    &:hover {
      background: var(--card-border-color);
    }
  }

  .card-subtitle {
    color: var(--title-color);
    transition: color 0.3s ease;
  }

  .carousel__item:hover {
    .card-title,
    .card-subtitle {
      color: var(--title-color);
    }
  }

  .swiper {
    border-radius: 18px;
    overflow: hidden;
  }
}

:deep(.swiper-pagination-container) {
  margin-top: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
}

:deep(.swiper-pagination-bullet) {
  background-color: var(--primary);
  opacity: 0.5;
}

:deep(.swiper-pagination-bullet-active) {
  background-color: var(--primary);
  opacity: 1;
}
</style>
