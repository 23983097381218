import {
  createRouter as createClientRouter,
  createWebHistory,
  type RouteRecordRaw,
} from 'vue-router'
import routes from 'pages-generated'
import { authGuard } from '@auth0/auth0-vue'

type AnyRouteRecord = RouteRecordRaw

const additionalRoutes: AnyRouteRecord[] = []

export function createRouter() {
  const allRoutes = markRoutesAsAuthRequired([...additionalRoutes, ...routes])

  console.log(routes)

  const router = createClientRouter({
    history: createWebHistory(),
    routes: allRoutes,
    scrollBehavior: (to, from, savedPosition) => {
      if (to.hash) {
        return { el: to.hash, behavior: 'smooth', top: to.hash ? 0 : undefined }
      }
      if (savedPosition) return savedPosition
      return { top: 0, behavior: 'smooth' }
    },
  })

  return router
}

function markRoutesAsAuthRequired(routes: AnyRouteRecord[]): AnyRouteRecord[] {
  return routes.map((route) => {
    if ('path' in route && route.path.startsWith('/app')) {
      return {
        ...route,
        meta: { ...route.meta, requiresAuth: true },
        beforeEnter: authGuard,
        children: route.children
          ? markRoutesAsAuthRequired(route.children)
          : undefined,
      } as AnyRouteRecord
    }
    return route
  })
}
